<template>
  <div>
    <div
      v-if="roles.isAdmin"
      class="p-1 mb-1"
      style="background-color: white; border-radius: 5px;"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <label class="saj-text">{{ $t("Subsidiary") }}:</label>
          <b-form-select
            v-model="selectedSub"
            size="md"
            class="saj-text"
            :options="subsidiaryList"
            @change="search = false"
          />
        </b-col>

        <b-col
          cols="2"
          class="mt-2"
        >
          <b-button
            variant="primary"
            class=""
            block
            style="margin-top: 3px"
            @click="getCore(), search = true"
          >

            <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
          </b-button>
        </b-col>
        <b-col
          cols="2"
          class="mt-2"
        >
          <b-button
            variant="primary"
            class=""
            block
            style="margin-top: 3px"
            @click="clearSearch()"
          >
            <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div
      v-if="(roles.isAdmin && selectedSub !== null && search) || (!roles.isAdmin && userDetails.business_unit !== null || search)"
    >
      <b-tabs
        card
      >
        <b-tab
          class="p-0 saj-title"
          :title="$t('Rating')"
        >
          <b-card
            no-body
            class="mt-1 p-1"
            style="height: 100%; overflow-x: hidden;"
          >
            <div>
              <b-tabs
                class="saj-title"
                style="width: 100%"
              >
                <b-tab
                  :title="$t('Rating')"
                  active
                >
                  <rating
                    :key="refreshTable"
                    @emit-rating-id="val => ratingId = val"
                  />
                </b-tab>
                <b-tab
                  :title="$t('Training Evaluation Rating')"
                >
                  <ratingPart3
                    :key="refreshTable"
                    @emit-rating-id="val => ratingId = val"
                  />
                </b-tab>
              </b-tabs>
            </div>
          </b-card>
        </b-tab>
        <b-tab
          class="p-0 saj-title"
          :title="$t('Core Competencies')"
        >
          <b-card
            no-body
            class="mt-1 p-1"
            style="height: 100%; overflow-x: hidden; min-height: 370px;"
          >
            <div>
              <b-tabs>
                <b-tab
                  v-for="(a, idx) in coreList"
                  :key="idx"
                  :title="isEnglish ? a.core_bi : a.core_bm"
                >
                  <competency
                    :key="refreshTable"
                    :core-id="a.id"
                    :subs-id="subsId"
                    @emit-subcore-data="val => subInfo = val"
                    @pass-id="val => coreId = val"
                    @pass-subcore-id="val => subcoreId = val"
                  />
                </b-tab>
              </b-tabs>
            </div>
          </b-card>
        </b-tab>
        <b-tab
          class="p-0 saj-title"
          :title="$t('Behavioural Competencies')"
        >
          <b-card
            no-body
            class="mt-1 p-1"
            style="height: 100%; overflow-x: hidden; min-height: 370px;"
          >
            <div>
              <behavioural
                :subsidiary-id="selectedSub || userDetails.business_unit"
              />
            </div>
          </b-card>
        </b-tab>
      </b-tabs>
    </div>

    <!-- Modal -->
    <b-modal
      id="edit-rating"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
      size="lg"
    >
      <EditRating
        :rating-id="ratingId"
        @close="$bvModal.hide('edit-rating')"
        @reload-rating="reload()"
      />
    </b-modal>
    <b-modal
      id="edit-rating-part3"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
      size="lg"
    >
      <EditRatingPart3
        :rating-id="ratingId"
        @close="$bvModal.hide('edit-rating-part3')"
        @reload-rating="reload()"
      />
    </b-modal>
    <b-modal
      id="new-subcore"
      :hide-header="true"
      :hide-footer="true"
      :centered="true"
      size="lg"
    >
      <subcore
        :is-update="false"
        :core-id="coreId"
        @close="$bvModal.hide('new-subcore')"
        @reload-subcore="reload()"
      />
    </b-modal>
    <b-modal
      id="edit-subcore"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
      size="lg"
    >
      <subcore
        :core-data="subInfo"
        @close="$bvModal.hide('edit-subcore')"
        @reload-subcore="reload()"
      />
    </b-modal>
    <b-modal
      id="delete-subcore"
      :hide-footer="true"
      :hide-header="true"
      :centered="true"
    >
      <deleteCore
        confirm-btn="Yes"
        @cancel="$bvModal.hide('delete-subcore')"
        @confirm="deleteSubcore()"
      />
    </b-modal>
    <!-- Modal Ends -->
  </div>
</template>
<script>
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BCol,
  BButton,
  BFormSelect,
} from "bootstrap-vue"

import rating from '@/views/pages/reference/reference-rating.vue'
import ratingPart3 from '@/views/pages/reference/reference-rating-part3.vue'
import EditRating from '@/views/pages/reference/reference-rating-edit.vue'
import EditRatingPart3 from '@/views/pages/reference/reference-rating-edit-part3.vue'
import competency from "@/component/competency/get_competency.vue"
import subcore from "@/component/competency/subcore.vue"
import behavioural from '@/views/pages/reference/reference-get-behavioural.vue'
import deleteCore from "@/pages/alert-modal.vue"
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    rating,
    ratingPart3,
    EditRating,
    EditRatingPart3,
    competency,
    subcore,
    behavioural,
    deleteCore,
  },
  props: {

  },
  data(){
    return {
      refreshTable: 0,
      ratingId: null,
      selectedSub: null,
      subsidiaryList: [{ text: `${this.$i18n.t('Choose Subsidiary')}`, value: null, disabled: true }],
      search: false,
      coreList: [],
      coreId: null,
      subInfo: null,
      subsId: null
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
    ...mapGetters(['userDetails', 'roles']),
  },
  mounted(){
    this.getSubsidiaryList()

    if (!this.roles.isAdmin){
      this.getCore()
    }
  },
  methods: {
    reload(){
      this.refreshTable += 1
    },
    getSubsidiaryList(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(res => {
        const sub = res.data.data.subsidiaries

        sub.forEach(data => {
          this.subsidiaryList.push({
            text: data.subsidiary_name,
            value: data.id,
          })
        })
      })
    },
    getCore(){
      // console.log(this.userDetails.business_unit)
      let subsId = ''

      if (this.roles.isAdmin){
        subsId = this.selectedSub
      } else {
      // console.log('core id', this.userDetails)
        subsId = this.userDetails.business_unit
      }

      this.subsId = subsId
      this.$axios.get(`${this.$baseUrl}/competency_subcore/get_cores?subsidiary_id=${subsId}`).then(response => {
        const res = response.data.data
        // console.log('>> core list', res)
        this.coreList = res.map(x => ({
          core_bi: x.name_bi,
          core_bm: x.name_bm,
          id: x.id,
        }))
      })
    },
    deleteSubcore(){
      const data = new FormData()
      data.append('subcore_id', this.subcoreId)

      // data.forEach(x => {
      //   console.log('subcore id', x)
      // })

      this.$axios.post(`${this.$baseUrl}/competency_subcore/delete_subcores_content`, data)
        .then(() => {
          // this.$emit('reload-subcore')
          this.reload()
          this.$bvModal.hide('delete-subcore')

          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Successfully deleted')}.`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        })
    },
    clearSearch(){
      this.selectedSub = null
      this.search = false
    },
  },
}
</script>
