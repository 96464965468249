<template>
  <div>
    <b-card
      no-body
      class="saj-title"
      style=""
    >
      <validation-observer ref="validateRating">
        <b-card-header class="p-1 justify-content-center">
          <h5
            class="saj-header "
            style=""
          >
            {{ $t("Edit Training Evaluation Rating") }}

          </h5>
        </b-card-header>
        <b-card-body>
          <div class="col-lg">
            <div
              class="card"
              style="
                "
            >

              <!-- Definition -->
              <b-row
                class="p-1 mb-2"
                style="
                              color: black;
                              background: #f7f7fc;
                              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                                0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                              border-radius: 15px;
                              height: 100%;
                            "
              >
                <b-row class="col-12 saj-text p-1 m-0">
                  <b-col
                    cols="2"
                    class="p-1 d-flex align-items-start"
                  >
                    {{ $t("Rating Scale") }}:
                  </b-col>
                  <b-col
                    cols="10"
                    class="p-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Rating Scale')"
                      rules="required"
                    >
                      <b-form-input
                        v-model="ratingData.scale"
                        class="saj-text"
                        type="text"
                        placeholder=""
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- Definition English -->
                  <b-col
                    cols="2"
                    class="p-1 d-flex align-items-start"
                  >
                    {{ $t("Definition in English") }}:
                  </b-col>
                  <b-col
                    cols="10"
                    class="p-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Definition in English')"
                      rules="required"
                    >
                      <textarea
                        v-model="ratingData.definition_en"
                        class="saj-text form-control flex-grow-1"
                        name="textarea"
                        style="height: 120px
                                      border: 1px solid #d8d6de;
                                      "
                        rows="5"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <!-- Definition Malay -->
                  <b-col
                    cols="2"
                    class="p-1 d-flex align-items-center"
                  >
                    {{ $t("Definition in Malay") }}:
                  </b-col>
                  <b-col
                    cols="10"
                    class="p-1 "
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Definition in Malay')"
                      rules="required"
                    >
                      <textarea
                        v-model="ratingData.definition_my"
                        class="saj-text form-control flex-grow-1"
                        name="textarea"
                        style="height: 120px
                                      border: 1px solid #d8d6de;
                                      "
                        rows="5"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="2"
                    class="p-1 d-flex align-items-start"
                  >
                    {{ $t("English Type") }}:
                  </b-col>
                  <b-col
                    cols="10"
                    class="p-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('English Type')"
                      rules="required"
                    >
                      <b-form-input
                        v-model="ratingData.type_en"
                        class="saj-text"
                        type="text"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <b-col
                    cols="2"
                    class="p-1 d-flex align-items-start"
                  >
                    {{ $t("Malay Type") }}:
                  </b-col>
                  <b-col
                    cols="10"
                    class="p-1"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('Malay Type')"
                      rules="required"
                    >
                      <b-form-input
                        v-model="ratingData.type_my"
                        class="saj-text"
                        type="text"
                        placeholder=""
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-row>
              <!-- Definition End-->

              <div
                class="d-flex justify-content-center mt-2"
                style=""
              >
                <button
                  class="btn m-1 saj-button"
                  aria-label="submit modal"
                  style="

                      color: white;
                      background: #0b103c !important;
                      border-color: #0b103c !important;
                    "
                  @click="editRating()"
                >
                  {{ $t('Save') }}
                </button>
                <button
                  class="btn btn-close m-1 saj-button"
                  aria-label="Close modal"
                  style="

                      color: white;
                      background: #ff0000;
                    "
                  @click="close()"
                >
                  {{ $t('Cancel') }}
                </button>
              </div>
            </div></div></b-card-body></validation-observer></b-card></div>

</template>
    <style>
    </style>
<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  // BBadge,

} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    // BBadge,
  },
  props: {
    ratingId: {
      type: null,
      default: null,
    },

  },
  data() {
    return {
      required,
      selectVisibility: [],
      subList: [],
      subcoreInfo: [],
      ratingData: [],
    }
  },
  mounted() {
    this.getScoreRatingById()
  },
  methods: {
    close() {
      this.$emit('close')
    },
    getScoreRatingById(){
      this.$axios.get(`${this.$baseUrl}/rating/getById?id=${this.ratingId}`).then(response => {
        this.ratingData = response.data.data
        // console.log(response)
      })
    },
    editRating(){
      this.$refs.validateRating.validate().then(success => {
        if (success){
          const data = new FormData()

          data.append('scale', this.ratingData.scale)
          data.append('definition_en', this.ratingData.definition_en)
          data.append('definition_my', this.ratingData.definition_my)
          data.append('type_en', this.ratingData.type_en)
          data.append('type_my', this.ratingData.type_my)
          data.append('id', this.ratingId)
          data.append('option', 'part3')

          //   data.forEach(a => {
          //     console.log("append edit part 3", a)
          //   })

          this.$axios.post(`${this.$baseUrl}/rating/update`, data)
            .then(() => {
              setTimeout(() => {
                this.$toast(
                  {
                    component: SAJToast,
                    props: {
                      title: `${this.$t('Rating successfully updated')}.`,
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                      titleColor: '#000',
                    },
                  },
                  {
                    position: "top-right",
                    type: 'info',
                  },
                )
                this.$emit('reload-rating')
                this.$emit('close')
              }, 1000)
            })
        }
      })
    },
  },
}
</script>
