<template>
  <div>
    <b-card
      no-body
      class="saj-title mr-1"
      style=""
    >
      <b-card-body class>
        <div class="col-lg">
          <b-table
            show-empty
            :items="ratingList"
            :fields="fields"
            responsive=""
            bordered
            class="mt-1 mb-1 saj-subtitle"
          >
            <!-- A custom formatted column -->
            <template #head()="data">
              <span
                class="saj-title"
                style=""
              >{{ $t(data.label) }}</span>
            </template>

            <template #empty="">
              <h4 style="text-align: center; font-style: italic">
                {{ $t('There are no records to show') }}
              </h4>
            </template>

            <template #cell(scale)="data">
              <div class="d-flex justify-content-center">
                {{ data.item.scale }}
              </div>
            </template>
            <template #cell(definition_en)="data">
              {{ data.item.definition_en }}
            </template>
            <template #cell(definition_my)="data">
              {{ data.item.definition_my }}
            </template>

            <template #cell(action)="data">
              <div
                class="d-flex justify-content-center"
              >
                <div v-if="roles.isAdmin">
                  <feather-icon
                    v-b-modal.edit-rating-part3
                    v-b-tooltip.hover.top="$t('Edit')"
                    icon="EditIcon"
                    size="25"
                    style="color: green"
                    class="mr-1"
                    @click="$bvModal.show('edit-rating-part3'), ratingDetails = data.item.id, getRatingId()"
                  />
                  <!-- <feather-icon
                    v-b-modal.delete-title
                    v-b-tooltip.hover.top="$t('Delete')"
                    icon="Trash2Icon"
                    size="25"
                    style="color: red"
                  /> -->
                </div>
                <div v-else>
                  {{ $t("Not available") }}
                </div>

              </div> </template></b-table>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BTable,
  VBTooltip,
} from "bootstrap-vue"
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BCardBody,
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {

  },
  data(){
    return {
      fields: [
        { key: 'scale', label: 'Rating Scale', thClass: 'text-center' },
        { key: 'definition_en', label: 'English Definition', thClass: 'text-center' },
        { key: 'definition_my', label: 'Malay Definition', thClass: 'text-center' },
        { key: 'type_en', label: 'English Type', thClass: 'text-center' },
        { key: 'type_my', label: 'Malay Type', thClass: 'text-center' },
        { key: 'action', label: 'Action', thClass: 'text-center' },
      ],
      ratingList: [],
      ratingDetails: null,
    }
  },
  computed: {
    ...mapGetters(['roles']),
  },
  mounted(){
    this.getScoreRating()
  },
  methods: {
    getScoreRating(){
      this.$axios.get(`${this.$baseUrl}/rating/getAll?option=part3`).then(response => {
        this.ratingList = response.data.data
        // console.log(response)
      })
    },
    getRatingId(){
      this.$emit('emit-rating-id', this.ratingDetails)
    },
  },
}
</script>
